.App {
  position: relative;
  background-image: url('floor_plans.svg');
  background-size: cover;
  width: 896px;
  height: 562px;
  margin: auto;
}

.spot {
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #39d239;
}

.occupied {
    background: #CD1111; 
}

.unauthorized {
    position: absolute;
    color: orange;
    font-size: 15px;
    animation: blinker 2s linear infinite;
}

.unknown {
  position: absolute;
  background: #b4b4b4;
  font-size: 15px;
}

@keyframes blinker {
  50% { opacity: 0; }
}
